import BalanceInfo from '@components/V3/BalanceInfo';
import BannerStack from '@components/V3/BannerStack';
import { banners } from '@components/V3/BannerStack/banners';
import EnrolledInCourses from '@components/V3/EnrolledInCourses';
import HarvesterBanner from '@components/V3/HarvesterBanner';
import MentorCapacityCard from '@components/V3/MentorCapacityCard';
import TeachingCourses from '@components/V3/TeachingCourses';
import UserHomeCard from '@components/V3/UserHomeCard';
import PageTitle from '@components/V3/Utils/PageTitle';
import SectionTitle from '@components/V3/Utils/SectionTitle';
import MonthlyNPSModal from '@modules/Base/Home/PendingNPS/MonthlyNPSModal';
import { MENTOR_ROLE } from '@shared/constants';
import {
  useAuthState,
  useCheckPendingNPS,
  useGetLatestMentorReport,
  useGetMentorCapacity,
  useGetMentorTeachingAndEnrolledInEnrollments,
  useHasMentorUpcomingStudents,
} from '@shared/react';
import { detectDeviceType } from '@shared/utils/string';
import { showMentorUploadInvoiceBanner } from '@shared/utils/uploadInvoiceBannerHelper';
import style from '@styles/components/reusables/page.module.scss';
import { Tooltip } from 'antd';
import React, { useEffect, useState } from 'react';
import MeetingsNeedingFeedbackMentor from '../../../../components/V3/MeetingsNeedingFeedbackMentor';
import NextMeetingsMentor from '../../../../components/V3/NextMeetingsMentor';
import { COLORS } from '../../../../v3/global';
import MentorCalendar from './Calendar';

const MentorHome: React.FC = () => {
  const { data, refetch } = useCheckPendingNPS();
  const firstUpgradeNPS = Math.random() < 0.5;

  const { data: lastReportStatus } = useGetLatestMentorReport();
  const { data: hasUpcomingStudents } = useHasMentorUpcomingStudents();

  const [showNpsModal, setShowNpsModal] = useState(false);

  const { userId, fullName, roles, avatar, folderId } = useAuthState();
  const isMentor = roles.includes(MENTOR_ROLE);

  const showUploadInvoiceBanner: boolean = showMentorUploadInvoiceBanner(
    isMentor,
    lastReportStatus?.status
  );

  const { data: mentorCapacity, isLoading: isLoadingCapacity } =
    useGetMentorCapacity(userId);

  const { data: enrollments } =
    useGetMentorTeachingAndEnrolledInEnrollments(userId);

  const [modalActive, setModalActive] = useState(false);

  const [currentModal, setCurrentModal] = useState('nps-modal');

  const handleCurrentModal = (modalName: string) => {
    setCurrentModal(modalName);
  };

  const handleSubmit = () => {
    setShowNpsModal(true);
  };

  const hasData = data && data?.type === 'monthly';
  const bannersToShow = [];

  if (showUploadInvoiceBanner) {
    bannersToShow.push(banners[0]);
  }

  if (hasData) {
    bannersToShow.push(banners[1]);
  }

  if (hasUpcomingStudents) {
    bannersToShow.push(banners[2]);
  }

  useEffect(() => {
    const isDateLongerThan30Days = () => {
      if (!mentorCapacity || Object.keys(mentorCapacity).length === 0) {
        setModalActive(true);
      } else {
        const updatedAtString = mentorCapacity?.updatedAt;

        if (!updatedAtString) {
          setModalActive(true);
        } else {
          const updatedAt = new Date(updatedAtString);
          const currentDate = new Date();

          const timeDifference = currentDate.getTime() - updatedAt.getTime();

          const daysDifference = timeDifference / (1000 * 60 * 60 * 24);

          if (daysDifference > 30) {
            setModalActive(true);
          }
        }
      }
    };

    if (!isLoadingCapacity) {
      isDateLongerThan30Days();
    }
  }, [isLoadingCapacity, mentorCapacity]);

  return (
    <div className={style.mentorDashboardContainer}>
      <div
        style={{
          backgroundColor: COLORS.SECTION_BACKGROUND,
          minHeight: '100vh',
        }}
        className={`${style.page} ${style.mentorDashboardLeftContainer}`}
      >
        <div className={`${style.mentorDashboardLeftContainerContent}`}>
          {currentModal === 'nps-modal' && data && data?.type === 'monthly' ? (
            <MonthlyNPSModal
              visible={true}
              firstUpgradeNPS={firstUpgradeNPS}
              handleCurrentModal={handleCurrentModal}
              onClose={() => {
                setShowNpsModal(false);
                refetch();
              }}
            />
          ) : (
            <MentorCapacityCard
              showButton={false}
              isModalActive={modalActive}
            />
          )}

          {showNpsModal && (
            <MonthlyNPSModal
              visible={showNpsModal}
              firstUpgradeNPS={firstUpgradeNPS}
              onClose={() => {
                setShowNpsModal(false);
                refetch();
              }}
            />
          )}

          <div
            style={{ marginBottom: '32px' }}
            className={`${style.mentorDashboardSectionMargin} ${style.mentorWelcomeMessage}`}
          >
            <PageTitle
              title={`Welcome back, ${fullName}`}
              description="Let's learn something new today!"
            />
          </div>

          {/*<div>*/}
          {/*  <BannerStack*/}
          {/*    handleSubmitFeedback={() => {*/}
          {/*      handleSubmit();*/}
          {/*    }}*/}
          {/*    banners={*/}
          {/*      showUploadInvoiceBanner && data && data?.type === 'monthly'*/}
          {/*        ? [banners[0], banners[1]]*/}
          {/*        : showUploadInvoiceBanner*/}
          {/*        ? [banners[0]]*/}
          {/*        : data && data?.type === 'monthly'*/}
          {/*        ? [banners[1]]*/}
          {/*        : []*/}
          {/*    }*/}
          {/*  />*/}
          {/*</div>*/}

          {/*{detectDeviceType() === 'Web' && (*/}
          {/*  <div*/}
          {/*    className={style.uploadBannerDesktopContainer}*/}
          {/*    style={{ minWidth: '100%' }}*/}
          {/*  >*/}
          {/*    <UploadInvoiceBanner />*/}
          {/*  </div>*/}
          {/*)}*/}

          {detectDeviceType() === 'Web' && (
            <div
              className={style.uploadBannerDesktopContainer}
              style={{ minWidth: '100%' }}
            >
              <BannerStack
                handleSubmitFeedback={() => {
                  handleSubmit();
                }}
                banners={bannersToShow}
              />
            </div>
          )}

          {/*{data && data?.type === 'monthly' && (*/}
          {/*  <div className={style.feedbackBannerContainerDesktop}>*/}
          {/*    <FeedbackBanner handleSubmit={handleSubmit} />*/}
          {/*  </div>*/}
          {/*)}*/}

          <div className={style.calendarTitleContainer}>
            <SectionTitle title="Calendar" />
          </div>

          <div className={style.mentorDashboardCalendarMargin}>
            <MentorCalendar />
          </div>

          <div className={style.mentorDashboardSectionMargin}>
            <HarvesterBanner />
          </div>

          <div className={style.mentorDashboardSectionMargin}>
            <TeachingCourses enrollments={enrollments} />
          </div>

          <div className={style.mentorDashboardSectionMargin}>
            <EnrolledInCourses enrollments={enrollments} />
          </div>
        </div>
      </div>

      <div
        style={{
          backgroundColor: COLORS.SECTION_BACKGROUND,
        }}
        className={`${style.page} ${style.mentorDashboardRightContainer}`}
      >
        {/*{detectDeviceType() === 'Mobile' && (*/}
        <div
          className={style.uploadBannerMobileContainer}
          style={{ marginBottom: '40px', marginTop: '16px' }}
        >
          <BannerStack
            handleSubmitFeedback={() => {
              handleSubmit();
            }}
            banners={bannersToShow}
          />
        </div>
        {/*)}*/}
        {/*{detectDeviceType() === 'Mobile' && (*/}
        {/*  <div*/}
        {/*    className={style.uploadBannerMobileContainer}*/}
        {/*    style={{ marginBottom: '56px' }}*/}
        {/*  >*/}
        {/*    <UploadInvoiceBanner />*/}
        {/*  </div>*/}
        {/*)}*/}
        {/*{data && data?.type === 'monthly' && (*/}
        {/*  <div className={style.feedbackBannerContainerMobile}>*/}
        {/*    <FeedbackBanner handleSubmit={handleSubmit} />*/}
        {/*  </div>*/}
        {/*)}*/}
        <div
          className={`${style.mentorDashboardSectionMargin} ${style.mentorDashboardBalanceUserContainer}`}
        >
          <div className="ml-auto">
            <UserHomeCard
              name={fullName}
              description={isMentor ? 'Mentor' : 'Student'}
              avatar={avatar}
            />
          </div>
        </div>
        <div style={{ marginBottom: '32px' }}>
          <SectionTitle title="Meetings Needing Feedback" />
        </div>
        <MeetingsNeedingFeedbackMentor isMentor={true} />
        <div style={{ marginBottom: '32px' }}>
          <div className={style.mentorDashboardMeetingSectionContainer}>
            <SectionTitle title="Scheduled Meetings" />
            {/*TODO Remove if no longer needed*/}
            {/*<div className={style.newMeetingDesktopButtonContainer}>*/}
            {/*  <NewMeetingButton />*/}
            {/*</div>*/}
          </div>
          <NextMeetingsMentor isMentor={true} />
        </div>
      </div>
    </div>
  );
};

export default MentorHome;
