import {
  BookOutlined,
  DollarOutlined,
  EuroCircleOutlined,
  PoundCircleOutlined,
  TeamOutlined,
  ClockCircleOutlined,
} from '@ant-design/icons';
import MyAccountIcon from '@assets/icons/Exclusion_2.svg';
import MyFilesIcon from '@assets/icons/folder.svg';
import KnowledgeBasePrimary from '@assets/icons/knowledge-base-icon-primary.svg';
import KnowledgeBaseWhite from '@assets/icons/knowledge-base-icon-white.svg';
import LogoutIcon from '@assets/icons/logout.svg';
import UELogoV3 from '@assets/icons/ue-logo-v3.svg';
import UELogo from '@assets/icons/ue-logo.svg';
import { ReactComponent as DriveIcon } from '@assets/icons/V4/drive-icon.svg';
import {
  ACCOUNT,
  COURSES,
  MY_STUDENTS,
  KNOWLEDGE_BASE_ASSISTANT,
  SESSION_HISTORY,
} from '@routes';
import { MENTOR_ROLE } from '@shared/constants';
import {
  useAuthActions,
  useAuthState,
  useMentorSessionNo,
} from '@shared/react';
import pageStyle from '@styles/components/reusables/page.module.scss';
import style from '@styles/views/header.module.scss';

import { Alert, Button, message, Tooltip } from 'antd';
import moment from 'moment';
import { useEffect, useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
import './header-style.scss';
import BalanceInfo from '../BalanceInfo';

type Props = {
  children: any;
};

const HeaderV3 = ({ children }: Props) => {
  const history = useHistory();
  const { userId, roles, rate } = useAuthState();
  const { logout } = useAuthActions();
  const { googleDrive, folderId } = useAuthState();
  const userRole = localStorage.getItem('userRole');

  const isDeactivated = !userId;

  const impersonateId = localStorage.getItem('impersonate-user-id');

  const [currency, setCurrency] = useState('$');

  const isHeaderVisible = !window.location.pathname.includes('meeting');
  const [screenWidth, setScreenWidth] = useState(window.innerWidth);

  const [knowledgebaseHover, setKnowledgeBaseHover] = useState(false);

  const [isMenuOpen, setMenuOpen] = useState(false);

  let isMentor: any = undefined;

  if (userRole === 'student') {
    isMentor = false;
  } else if (userRole === 'mentor') {
    isMentor = true;
  } else {
    isMentor = roles.includes(MENTOR_ROLE);
  }

  const handleMenuClick = () => {
    setMenuOpen(!isMenuOpen);
  };

  const isImpersonating = () => !!localStorage.getItem('impersonate-user-id');

  useEffect(() => {
    // Function to update screenWidth when the window is resized
    const handleResize = () => {
      setScreenWidth(window.innerWidth);
    };

    // Event listener for window resize
    window.addEventListener('resize', handleResize);

    // Cleanup the event listener on component unmount
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  useEffect(() => {
    if (isMentor) {
      switch (rate?.currency) {
        case 'USD':
          setCurrency('$');
          break;
        case 'EUR':
          setCurrency('€');
          break;
        case 'GBP':
          setCurrency('£');
          break;
        case 'R0N':
          setCurrency('RON');
          break;
        default:
          setCurrency('$');
          break;
      }
    }
  }, [rate]);

  const { data: sessions } = useMentorSessionNo(impersonateId ?? userId);

  const stopImpersonating = () => {
    localStorage.clear();

    message.loading('Stopping impersonate...', 0);

    setTimeout(() => {
      window.location.href = '/';
    }, 2000);
  };

  if (!isHeaderVisible) return null;

  const balanceDetails = (
    <div className={style.tooltipContainer}>
      <div className={style.tooltipInfoText}>Estimated payment</div>
      <div>
        for {moment().startOf('month').format('DD.MM')} -{' '}
        {moment().format('DD.MM')}
      </div>
      <div className={style.tooltipBalanceValue}>
        {currency}
        {rate?.amount && sessions?.noOfSessions
          ? rate?.amount * sessions?.noOfSessions
          : 0}
      </div>
      <div className={style.tooltipInfoText}>
        {sessions?.noOfSessions}{' '}
        {sessions?.noOfSessions !== 1 ? 'Sessions' : 'Session'}
      </div>
      <Button
        onClick={() => history.push('/session-history')}
        className={style.actionButton}
      >
        Session History
      </Button>
    </div>
  );

  const closeMenuHandler = () => setMenuOpen(false);

  return (
    <div className="header-banner-container">
      <div className="header-container">
        <div className="burger-left-container"></div>

        <Link to={'/home'}>
          {/*<img*/}
          {/*  style={{ width: '40px', marginTop: '32px', marginBottom: '56px' }}*/}
          {/*  src={UELogo}*/}
          {/*  alt="logo-upgrade"*/}
          {/*/>*/}
          <div
            onClick={() => {
              isMenuOpen && closeMenuHandler();
            }}
            className="image-container"
            style={{
              display: 'flex',
              justifyContent: 'center',
            }}
          >
            <img
              className="default-image"
              style={{
                width: '32px',
                marginTop: '32px',
                marginBottom: '56px',
                marginLeft: '4px',
              }}
              src={UELogo}
              alt="logo-upgrade"
            />
            <img
              className="hover-image"
              style={{
                width: '32px',
                marginTop: '32px',
                marginBottom: '56px',
                marginLeft: '4px',
              }}
              src={UELogoV3}
              alt="logo-upgrade"
            />
          </div>
        </Link>

        <div className="desktop-links-container">
          {/*HEADER DESKTOP LINKS HERE*/}

          {isMentor && (
            <Tooltip
              zIndex={1}
              color="transparent"
              placement="right"
              title={
                <div style={{ padding: '24px' }} className="tooltip-container">
                  <div className="tooltip-title">My Students</div>
                  <div className="tooltip-description">
                    Manage your students
                  </div>
                </div>
              }
            >
              <Link to={MY_STUDENTS}>
                <div className="circle-icon">
                  <TeamOutlined className="menu-icon" />
                </div>
              </Link>
            </Tooltip>
          )}

          {!isDeactivated && (
            <>
              <Tooltip
                zIndex={1}
                color="transparent"
                placement="right"
                title={
                  <div
                    style={{ padding: '24px' }}
                    className="tooltip-container"
                  >
                    <div className="tooltip-title">Courses</div>
                    <div className="tooltip-description">
                      Explore your courses collection
                    </div>
                  </div>
                }
              >
                <Link to={COURSES}>
                  <div className="circle-icon">
                    <BookOutlined className="menu-icon" />
                  </div>
                </Link>
              </Tooltip>

              <Tooltip
                zIndex={1}
                color="transparent"
                placement="right"
                title={
                  <div
                    style={{ padding: '24px' }}
                    className="tooltip-container"
                  >
                    <div className="tooltip-title">My Files</div>
                    <div className="tooltip-description">
                      Access and manage your files
                    </div>
                  </div>
                }
              >
                <Link
                  to={{
                    pathname: `https://drive.google.com/drive/folders/${googleDrive}`,
                  }}
                  target="_blank"
                >
                  <div className="circle-icon">
                    <img style={{ width: '16px' }} src={MyFilesIcon} />
                  </div>
                </Link>
              </Tooltip>
            </>
          )}

          <Tooltip
            zIndex={1}
            color="transparent"
            placement="right"
            title={
              <div style={{ padding: '24px' }} className="tooltip-container">
                <div className="tooltip-title">Old Drive</div>
                <div className="tooltip-description">Access Old Drive</div>
              </div>
            }
          >
            <Link
              to={{
                pathname: `https://drive.google.com/drive/folders/${folderId}`,
              }}
              target="_blank"
            >
              <div className="circle-icon">
                {/* <img style={{ width: '16px' }} src={MyFilesIcon} /> */}
                <DriveIcon className="w-6 text-black" />
              </div>
            </Link>
          </Tooltip>

          {isMentor && (
            <Tooltip
              zIndex={1}
              color="transparent"
              placement="right"
              title={
                <div style={{ padding: '24px' }} className="tooltip-container">
                  <div className="tooltip-title">Session History</div>
                  <div className="tooltip-description">
                    Track your session history
                  </div>
                </div>
              }
            >
              <div
                onClick={() => history.push('/session-history')}
                className="circle-icon"
              >
                <ClockCircleOutlined className="menu-icon" />
              </div>
            </Tooltip>
          )}

          {isMentor && (
            <Tooltip
              zIndex={1}
              color="transparent"
              placement="right"
              title={
                <div style={{ padding: '24px' }} className="tooltip-container">
                  <div className="tooltip-title">Knowledge Base Assistant</div>
                  <div className="tooltip-description">
                    Use AI to explore our existing knowledge base
                  </div>
                </div>
              }
            >
              <Link
                onMouseEnter={() => setKnowledgeBaseHover(true)}
                onMouseLeave={() => setKnowledgeBaseHover(false)}
                to={KNOWLEDGE_BASE_ASSISTANT}
              >
                <div className="circle-icon">
                  {/*<TeamOutlined className="menu-icon" />*/}
                  {knowledgebaseHover ? (
                    <img src={KnowledgeBasePrimary} alt="knowledge-base" />
                  ) : (
                    <img src={KnowledgeBaseWhite} alt="knowledge-base" />
                  )}
                </div>
              </Link>
            </Tooltip>
          )}

          {!isDeactivated && (
            <>
              <div className="icon-divider"></div>

              <Tooltip
                zIndex={1}
                color="transparent"
                placement="right"
                title={
                  <div
                    style={{ padding: '24px' }}
                    className="tooltip-container"
                  >
                    <div className="tooltip-title">My Account</div>
                    <div className="tooltip-description">
                      Manage your profile
                    </div>
                  </div>
                }
              >
                <Link to={ACCOUNT}>
                  <div className="circle-icon">
                    <img style={{ width: '16px' }} src={MyAccountIcon} />
                  </div>
                </Link>
              </Tooltip>
            </>
          )}

          <Tooltip
            zIndex={1}
            color="transparent"
            placement="right"
            title={
              <div style={{ padding: '24px' }} className="tooltip-container">
                <div className="tooltip-title">Logout</div>
                <div className="tooltip-description">
                  Logout securely from your account
                </div>
              </div>
            }
          >
            <div onClick={logout} className="circle-icon">
              <img style={{ width: '16px' }} src={LogoutIcon} />
            </div>
          </Tooltip>
        </div>

        <div className="burger-right-container">
          <div
            className={`burger-menu ${isMenuOpen ? 'open' : ''}`}
            onClick={handleMenuClick}
          >
            <div className="bar"></div>
            <div className="bar"></div>
            <div className="bar"></div>
          </div>
        </div>
      </div>

      <div className="flex flex-col w-full">
        {/*OLD REPORT AND INVOICE BANNER*/}
        {/*TODO Remove this if no longer needed*/}
        {/*{!isMenuOpen &&*/}
        {/*((isMentor &&*/}
        {/*  Number(moment().format('DD')) >= 2 &&*/}
        {/*  Number(moment().format('DD')) <= 7) ||*/}
        {/*  (isStaging && isMentor) ||*/}
        {/*  (isDevelopment && isMentor)) ? (*/}
        {/*  <div>*/}
        {/*    <div*/}
        {/*      style={{*/}
        {/*        display: 'flex',*/}
        {/*        flexDirection: 'row',*/}
        {/*        justifyContent: 'space-around',*/}
        {/*        alignItems: 'center',*/}
        {/*        minHeight: '132px',*/}
        {/*        background: '#33A9AB 0% 0% no-repeat padding-box',*/}
        {/*        flexWrap: 'wrap',*/}
        {/*      }}*/}
        {/*    >*/}
        {/*      <div*/}
        {/*        style={{*/}
        {/*          display: 'flex',*/}
        {/*          flexDirection: 'row',*/}
        {/*          justifyContent: 'center',*/}
        {/*          flexWrap: 'wrap',*/}
        {/*          marginTop: '6px',*/}
        {/*          marginBottom: '6px',*/}
        {/*        }}*/}
        {/*      >*/}
        {/*        <BellIcon style={{ width: '68px', color: '#ffffff' }} />*/}
        {/*        <div*/}
        {/*          style={{*/}
        {/*            display: 'flex',*/}
        {/*            flexDirection: 'column',*/}
        {/*            justifyContent: 'center',*/}
        {/*            textAlign: 'left',*/}
        {/*            margin: '0 20px',*/}
        {/*          }}*/}
        {/*        >*/}
        {/*          <span*/}
        {/*            style={{*/}
        {/*              fontSize: '22px',*/}
        {/*              font: 'normal normal medium 30px/36px Urbane Rounded',*/}
        {/*              letterSpacing: '0px',*/}
        {/*              color: '#FFFFFF',*/}
        {/*              opacity: 1,*/}
        {/*            }}*/}
        {/*          >*/}
        {/*            Reminder - Add invoice for{' '}*/}
        {/*            {moment().subtract(1, 'month').format('MMMM')}*/}
        {/*          </span>*/}
        {/*          <span*/}
        {/*            style={{*/}
        {/*              color: '#ffffff',*/}
        {/*              font: 'normal normal 300 20px/24px Urbane Rounded',*/}
        {/*              fontSize: '16px',*/}
        {/*              letterSpacing: '0px',*/}
        {/*              opacity: '1',*/}
        {/*            }}*/}
        {/*          >*/}
        {/*            Please upload the invoice for{' '}*/}
        {/*            {moment().subtract(1, 'month').format('MMMM')} by 7*/}
        {/*            {' ' + moment().format('MMMM')}*/}
        {/*            {Number(moment().format('DD')) >= 2 &&*/}
        {/*              Number(moment().format('DD')) <= 7}*/}
        {/*          </span>*/}
        {/*        </div>*/}
        {/*      </div>*/}
        {/*      <div*/}
        {/*        style={{*/}
        {/*          display: 'flex',*/}
        {/*          flexDirection: 'row',*/}
        {/*          justifyContent: 'center',*/}
        {/*          flexWrap: 'wrap',*/}
        {/*          marginTop: '10px',*/}
        {/*          marginBottom: '10px',*/}
        {/*        }}*/}
        {/*      >*/}
        {/*        <Button*/}
        {/*          onClick={() =>*/}
        {/*            saveByteArray(*/}
        {/*              'Monthly_Report.pdf',*/}
        {/*              pdfData?.pdfBuffer?.data*/}
        {/*            )*/}
        {/*          }*/}
        {/*          loading={!pdfData}*/}
        {/*          className={`${button.actionButton}`}*/}
        {/*          style={{*/}
        {/*            border: '1.25px solid #191919',*/}
        {/*            margin: '0px 10px',*/}
        {/*            backgroundColor: 'transparent',*/}
        {/*            borderColor: '#ffffff',*/}
        {/*            color: '#ffffff',*/}
        {/*          }}*/}
        {/*        >*/}
        {/*          <span style={{ width: '170px' }}>*/}
        {/*            {!pdfData ? 'Loading...' : 'View Monthly Report'}*/}
        {/*          </span>*/}
        {/*        </Button>*/}
        {/*        /!*{!pdfData ? (*!/*/}
        {/*        /!*  <span>loading...</span>*!/*/}
        {/*        /!*) : (*!/*/}
        {/*        /!*  <div>*!/*/}
        {/*        /!*    <button onClick={() => saveByteArray("Monthly_Report.pdf", pdfData?.pdfBuffer?.data)}>download pdf2</button>*!/*/}
        {/*        /!*  </div>*!/*/}
        {/*        /!*)}*!/*/}
        {/*        {Modal}*/}
        {/*        {}*/}
        {/*        <Button*/}
        {/*          type="primary"*/}
        {/*          disabled={lastReport?.status === 2}*/}
        {/*          onClick={() => show({})}*/}
        {/*          style={{*/}
        {/*            border: '2px solid #000000',*/}
        {/*            margin: '0px 10px',*/}
        {/*            cursor:*/}
        {/*              lastReport?.status === 2 ? 'not-allowed' : 'pointer',*/}
        {/*          }}*/}
        {/*        >*/}
        {/*          {lastReport?.status === 2*/}
        {/*            ? 'Invoice Uploaded'*/}
        {/*            : 'Upload Invoice'}*/}
        {/*        </Button>*/}
        {/*      </div>*/}
        {/*    </div>*/}
        {/*  </div>*/}
        {/*) : null}*/}
        {isImpersonating() && (
          <Alert
            message="You are currently impersonating another user's account. Click to stop."
            type="info"
            onClick={stopImpersonating}
            showIcon
            style={{ cursor: 'pointer', textAlign: 'center' }}
          />
        )}
        {isMenuOpen && screenWidth < 1017 ? (
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              backgroundColor: '#121212',
              height: '100vh',
              paddingLeft: '48px',
              paddingTop: '72px',
            }}
          >
            {/*HEADER MOBILE LINKS HERE*/}
            {isMentor && (
              <Link
                onClick={closeMenuHandler}
                className="mobile-link-item"
                to={MY_STUDENTS}
              >
                My Students
              </Link>
            )}

            <Link
              onClick={closeMenuHandler}
              className="mobile-link-item"
              to={COURSES}
            >
              Courses
            </Link>

            <Link
              onClick={closeMenuHandler}
              to={{
                pathname: `https://drive.google.com/drive/folders/${googleDrive}`,
              }}
              target="_blank"
              className="mobile-link-item"
            >
              My Files
            </Link>

            <Link
              onClick={closeMenuHandler}
              to={{
                pathname: `https://drive.google.com/drive/folders/${folderId}`,
              }}
              target="_blank"
              className="mobile-link-item"
            >
              Old Drive
            </Link>

            {isMentor && (
              <Link
                onClick={() => {
                  closeMenuHandler();
                  history.push('/session-history');
                }}
                className="mobile-link-item"
                to={SESSION_HISTORY}
              >
                Session History
              </Link>
            )}

            {isMentor && (
              <Link
                onClick={closeMenuHandler}
                className="mobile-link-item"
                to={KNOWLEDGE_BASE_ASSISTANT}
              >
                Knowledge Base Assistant
              </Link>
            )}

            <div className="icon-divider"></div>

            <Link
              onClick={closeMenuHandler}
              to={ACCOUNT}
              className="mobile-link-item"
            >
              My Account
            </Link>

            <Link
              to=""
              onClick={() => {
                logout();
                closeMenuHandler();
              }}
              className="mobile-link-item"
            >
              Logout
            </Link>
          </div>
        ) : (
          children
        )}
      </div>
    </div>
  );
};

export default HeaderV3;
