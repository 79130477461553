import './login-error-screen.scss';
import { ReactComponent as DeactivatedIcon } from '@assets/icons/V4/empty-states/deactivated-icon.svg';
import { useAuth0 } from '@auth0/auth0-react';
import HeaderV3 from '@components/V3/HeaderV3';
import EmptyStateCard from 'apps/agora/src/views/UserView/components/EmptyStateCard';

const LoginErrorView = () => {
  const { loginWithRedirect } = useAuth0();

  return (
    <div
      style={{
        backgroundColor: 'black',
        width: '100vw',
        height: '100vh',
      }}
      className="scrollableContainer"
    >
      <HeaderV3>
        <div className="w-full h-screen flex items-center justify-center ">
          <EmptyStateCard
            icon={<DeactivatedIcon />}
            text={`Your account has been deactivated. Please contact support for assistance.`}
            buttonText="Take me to login page"
            onClick={loginWithRedirect}
          />
        </div>
      </HeaderV3>
    </div>
  );
};

export default LoginErrorView;
