import { ReactComponent as CompletedIcon } from '@assets/icons/V4/empty-states/completed-meetings-icon.svg';
import { ReactComponent as EmptyStateIcon } from '@assets/icons/V4/empty-states/sad-calendar.svg';
import {
  useAuthState,
  useMyEnrollmentsWithPopulatedCourses,
  useUpcomingMeetings,
} from '@shared/react';
import style from '@styles/views/home.module.scss';
import { Spin, Typography } from 'antd';
import React, { useState } from 'react';
import { COLORS } from '../../../../../v3/global';
import EmptyStateCard from '../../../components/EmptyStateCard';
import NextMeetingStudent from './NextMeetingStudent';

const NextMeetingsStudent: React.FC = () => {
  const { data, isLoading, isError } = useUpcomingMeetings();
  const { userId } = useAuthState();

  const { data: enrollments } = useMyEnrollmentsWithPopulatedCourses();

  const impersonateId = localStorage.getItem('impersonate-user-id');

  const [viewAll, setViewAll] = useState(false);

  const meetings = data?.filter(
    (meeting) =>
      meeting.mentor?._id !== (impersonateId ?? userId) &&
      meeting.attended.student === false &&
      meeting.feedback?.length === 0 &&
      !meeting.mentorFeedback?.message
  );

  if (isLoading) {
    return <Spin />;
  }
  if (isError) {
    return (
      <Typography.Paragraph style={{ color: COLORS.TEXT_PRIMARY }}>
        An error has occurred...
      </Typography.Paragraph>
    );
  }

  if (
    enrollments?.length !== 0 &&
    enrollments?.filter((enrollment) => enrollment?.ended === true)?.length ===
      enrollments?.length
  ) {
    return (
      <EmptyStateCard
        icon={<CompletedIcon />}
        text={`You've completed all your courses and have no more meetings scheduled. 
Congrats on your achievement!`}
      />
    );
  }

  return (
    <div className={style.meetings}>
      {!meetings?.length ? (
        <EmptyStateCard
          icon={<EmptyStateIcon />}
          text={`No scheduled meetings. 
Talk to your mentor to schedule a meeting & stay on track.`}
        />
      ) : null}

      {!viewAll && meetings?.length
        ? meetings.map((meeting, index) =>
            index < 5 ? (
              <NextMeetingStudent key={meeting._id} meeting={meeting} />
            ) : null
          )
        : null}

      {viewAll && meetings?.length
        ? meetings.map((meeting, index) => (
            <NextMeetingStudent key={meeting._id} meeting={meeting} />
          ))
        : null}

      {meetings && meetings?.length > 5 ? (
        <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
          <span
            style={{
              cursor: 'pointer',
              textDecoration: 'underline',
              font: 'normal normal medium 13px/16px Urbane Rounded',
              fontSize: '13px',
              color: COLORS.PRIMARY,
              alignSelf: 'flex-end',
            }}
            onClick={() => setViewAll(!viewAll)}
          >
            {viewAll ? 'View less' : 'View all'}
          </span>
        </div>
      ) : null}
    </div>
  );
};

export default NextMeetingsStudent;
