import Button from '@components/V4/Button';
import React, { ReactNode } from 'react';

interface EmptyStateCardProps {
  icon: ReactNode;
  text: string | ReactNode;
  buttonText?: string;
  onClick?: () => void;
}

const EmptyStateCard = (props: EmptyStateCardProps) => {
  const { icon, text, buttonText, onClick } = props;
  return (
    <div className="flex flex-col gap-4 items-center justify-center w-full ">
      {icon}

      <p className="text-xxs text-customGrey font-semibold text-center max-w-64 leading-4 whitespace-pre-wrap">
        {text}
      </p>

      {buttonText && (
        <Button variant="secondary" buttonText={buttonText} onClick={onClick} />
      )}
    </div>
  );
};

export default EmptyStateCard;
