import { ReactComponent as EmptyStateIcon } from '@assets/icons/V4/empty-states/sad-calendar.svg';
import NewMeetingModal from '@components/NewMeetingModal/NewMeetingModal';
import { useModal } from '@hooks';
import { useUpcomingMeetings } from '@shared/react';
import style from '@styles/views/home.module.scss';
import { Spin, Typography } from 'antd';
import EmptyStateCard from 'apps/agora/src/views/UserView/components/EmptyStateCard';
import React, { useState } from 'react';
import { COLORS } from '../../../v3/global';
import NextMeetingMentor from './NextMeetingMentor';
type Props = {
  isMentor?: boolean;
};

const NextMeetingsMentor = ({ isMentor }: Props) => {
  const { data: meetings, isLoading, isError } = useUpcomingMeetings();
  const [Modal, show] = useModal(NewMeetingModal);

  const [viewAll, setViewAll] = useState(false);

  if (isLoading) {
    return <Spin />;
  }
  if (isError) {
    return (
      <Typography.Paragraph
        style={{ color: COLORS.TEXT_PRIMARY, fontSize: '16px' }}
      >
        An error has occurred...
      </Typography.Paragraph>
    );
  }

  return (
    <div className={style.meetings}>
      {!meetings?.length ? (
        <>
          {Modal}
          <EmptyStateCard
            icon={<EmptyStateIcon />}
            text={
              <>
                You have no meetings lined up...
                <br />
                Keep things rolling by scheduling your next meeting with your
                students!
              </>
            }
            buttonText="Add Meeting"
            onClick={() =>
              show({
                isOnHomePage: true,
              })
            }
          />
        </>
      ) : null}

      {!viewAll && meetings?.length
        ? meetings.map((meeting, index) =>
            index < 5 ? (
              <div>
                <NextMeetingMentor
                  key={meeting._id}
                  meeting={meeting}
                  isMentor={isMentor}
                />
              </div>
            ) : null
          )
        : null}

      {viewAll && meetings?.length
        ? meetings.map((meeting, index) => (
            <div>
              <NextMeetingMentor
                key={meeting._id}
                meeting={meeting}
                isMentor={isMentor}
              />
            </div>
          ))
        : null}

      {meetings && meetings?.length > 5 ? (
        <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
          <span
            style={{
              cursor: 'pointer',
              textDecoration: 'underline',
              font: 'normal normal medium 13px/16px Urbane Rounded',
              fontSize: '13px',
              color: COLORS.PRIMARY,
              alignSelf: 'flex-end',
            }}
            onClick={() => setViewAll(!viewAll)}
          >
            {viewAll ? 'View less' : 'View all'}
          </span>
        </div>
      ) : null}
    </div>
  );
};

export default NextMeetingsMentor;
