// TODO Refactor

import { NewMeeting } from '@shared/common';
import { useRegisterMentorFeedback } from '@shared/react';
import styles from '@styles/components/mentor-feedback.module.scss';
import { message } from 'antd';
import { Explanations } from 'apps/agora/src/views/UserView/Home/Common/Buttons/GiveFeedbackButtonMentor';
import React, { useState } from 'react';
import { COLORS } from '../../../../v3/global';
import MentorFeedbackModalTemplate from './MentorFeedbackModalTemplate';
import InputItem from './ModalsSteps/InputItem';
import {
  engagementCardsOptions,
  progressCardsOptions,
} from './ModalsSteps/SelectCard';
import SelectStep from './ModalsSteps/SelectStep';
import Step1 from './ModalsSteps/Step1';
import { regularModalMessage } from './ModalsSteps/helperFunctions';
import {
  engagementOptions,
  homeworkOptions,
  progressOptions,
} from './ModalsSteps/selectOptions';

interface MentorFeedbackModalSessionProps {
  meeting: NewMeeting;
  ratingExplanation: Explanations;
  setRatingExplanation: React.Dispatch<React.SetStateAction<Explanations>>;
}

export type Option = {
  name: string;
  selected: boolean;
};

const MentorFeedbackModalSession = (props: MentorFeedbackModalSessionProps) => {
  const { meeting, ratingExplanation, setRatingExplanation } = props;
  const [visible, setVisible] = useState(true);

  const [homework, setHomework] = useState({ index: -1, name: '' });
  const [engagement, setEngagement] = useState({ index: -1, name: '' });
  const [progress, setProgress] = useState({ index: -1, name: '' });

  const [goodThing, setGoodThing] = useState('');
  const [goodThing1, setGoodThing1] = useState('');
  const [goodThing2, setGoodThing2] = useState('');
  const [goodThing3, setGoodThing3] = useState('');

  const [ratingValueStep0, setRatingValueStep0] = useState(0);
  const [ratingValueStep1, setRatingValueStep1] = useState(0);
  const [ratingValueStep2, setRatingValueStep2] = useState(0);

  const totalStepsIndex = 4;
  const [currentStep, setCurrentStep] = useState(0);

  const { mutate: registerFeedback, isLoading: registeringFeedback } =
    useRegisterMentorFeedback(meeting._id, {
      onSuccess: () => {
        setVisible(false);
        message.success('Thanks for your feedback!');
        setHomework({ index: -1, name: '' });
        setEngagement({ index: -1, name: '' });
        setProgress({ index: -1, name: '' });
        setGoodThing('');
        setCurrentStep(0);
        homeworkOptions.forEach((option) => {
          Object.values(option)[0].forEach(
            (value: any) => (value.selected = false)
          );
        });
        progressOptions.forEach((option) => {
          Object.values(option)[0].forEach(
            (value: any) => (value.selected = false)
          );
        });
        engagementOptions.forEach((option) => {
          Object.values(option)[0].forEach(
            (value: any) => (value.selected = false)
          );
        });
      },
    });

  const studentName = meeting.students[0]?.fullName;

  const increaseStep = () => {
    setCurrentStep((prev) => prev + 1);
  };

  const decreaseStep = () => {
    setCurrentStep((prev) => prev - 1);
  };

  const handleRatingValueStep0 = (value: any) => {
    setRatingValueStep0(value);
  };

  const handleRatingValueStep1 = (value: any) => {
    setRatingValueStep1(value);
  };

  const handleRatingValueStep2 = (value: any) => {
    setRatingValueStep2(value);
  };

  const ratingExplanationHandler = (
    value: any,
    explanation: keyof Explanations
  ) => {
    setRatingExplanation((prev) => ({
      ...prev,
      [explanation]: value,
    }));
  };

  // useEffect(() => {
  //   engagement.index !== -1 &&
  //     setEngagementActiveOptions(
  //       Object.values(engagementOptions[engagement.index])[0] || []
  //     );
  // }, [engagement]);

  // useEffect(() => {
  //   progress.index !== -1 &&
  //     setProgressActiveOptions(
  //       Object.values(progressOptions[progress.index])[0] || []
  //     );
  // }, [progress]);

  return (
    <MentorFeedbackModalTemplate
      meeting={meeting}
      visible={visible}
      setVisible={setVisible}
      hasProgressBar={true}
      totalSteps={totalStepsIndex}
      currentStep={currentStep}
    >
      {currentStep === 0 ? (
        <Step1
          studentName={studentName}
          increaseStep={increaseStep}
          meeting={meeting}
          hideFeedbackModal={() => setVisible(false)}
        />
      ) : currentStep === 1 ? (
        <div>
          <SelectStep
            title=" How would you rate the student's homework?"
            categoryCardsOptions={engagementCardsOptions}
            activeValue={homework}
            handleSelect={setHomework}
            increaseStep={increaseStep}
            decreaseStep={decreaseStep}
            ratingValueStep={ratingValueStep0}
            handleRatingValueStep={handleRatingValueStep0}
            ratingExplanation={ratingExplanation.explanation0}
            handleRatingExplanation={(e) =>
              ratingExplanationHandler(e.target.value, 'explanation0')
            }
            inputItem={
              <InputItem
                subtitle="*Please explain your rating:"
                value={ratingExplanation.explanation0}
                handleChange={(e: any) =>
                  ratingExplanationHandler(e.target.value, 'explanation0')
                }
                height="5rem"
                width="532px"
                descriptionWidth="22rem"
                minCharactersRequired={100}
              />
            }
          />
        </div>
      ) : currentStep === 2 ? (
        <div>
          <SelectStep
            title="How was the student's engagement during this session?"
            categoryCardsOptions={engagementCardsOptions}
            activeValue={engagement}
            handleSelect={setEngagement}
            increaseStep={increaseStep}
            decreaseStep={decreaseStep}
            ratingValueStep={ratingValueStep1}
            handleRatingValueStep={handleRatingValueStep1}
            ratingExplanation={ratingExplanation.explanation1}
            handleRatingExplanation={(e) =>
              ratingExplanationHandler(e.target.value, 'explanation1')
            }
            inputItem={
              <InputItem
                subtitle="*Please explain your rating:"
                value={ratingExplanation.explanation1}
                handleChange={(e: any) =>
                  ratingExplanationHandler(e.target.value, 'explanation1')
                }
                height="5rem"
                width="532px"
                descriptionWidth="22rem"
                minCharactersRequired={100}
              />
            }
          />
        </div>
      ) : currentStep === 3 ? (
        <div>
          <SelectStep
            title="How would you rate the student’s overall progress in this course?"
            categoryCardsOptions={progressCardsOptions}
            activeValue={progress}
            handleSelect={setProgress}
            ratingValueStep={ratingValueStep2}
            increaseStep={increaseStep}
            decreaseStep={decreaseStep}
            handleRatingValueStep={handleRatingValueStep2}
            ratingExplanation={ratingExplanation.explanation2}
            handleRatingExplanation={(e) =>
              ratingExplanationHandler(e.target.value, 'explanation2')
            }
            inputItem={
              <InputItem
                subtitle="*Please explain your rating:"
                value={ratingExplanation.explanation2}
                handleChange={(e: any) =>
                  ratingExplanationHandler(e.target.value, 'explanation2')
                }
                height="5rem"
                width="532px"
                descriptionWidth="22rem"
                minCharactersRequired={100}
              />
            }
          />
        </div>
      ) : (
        <>
          <InputItem
            title="Topics Covered in Session"
            subtitle="What are the topics that were covered in this session?"
            description={
              <span>
                Please provide a list of the main topics that were covered
                during the mentoring session. This will help track the subjects
                discussed and provide a summary of the session's focus.
                <br />
                <br /> <span style={{ fontWeight: 'bold' }}>Example:</span> The
                topics covered in this session included: Newton's laws of
                motion, Projectile motion and related equations and the
                Conservation of energy in mechanical systems
              </span>
            }
            value={goodThing1}
            handleChange={(e: any) => setGoodThing1(e.target.value)}
            height="5rem"
            descriptionWidth="22rem"
            required
          />
          <InputItem
            title="Topics to be Covered Next"
            subtitle="What are the topics that will be covered next?"
            description={
              <span>
                Please list the topics that are planned to be covered in the
                upcoming mentoring sessions. This will help in preparing the
                student for future discussions and ensure a smooth progression
                in their academic journey. <br />
                <br /> <span style={{ fontWeight: 'bold' }}>Example:</span> The
                topics that are planned to be covered in the upcoming sessions
                include: Introduction to organic chemistry, Cell structure and
                functions in biology and Literary analysis techniques for
                English literature
              </span>
            }
            value={goodThing2}
            handleChange={(e: any) => setGoodThing2(e.target.value)}
            height="5rem"
            descriptionWidth="22rem"
            // minCharactersRequired={1}
            required
          />
          <InputItem
            title="Anything the Parent Can Help With"
            subtitle="Is there anything specific the student's parent(s) can assist with to support their academic journey?"
            description={
              <span>
                If there are any areas where parental involvement can be
                beneficial, such as providing additional resources, creating a
                conducive study environment, or offering emotional support,
                please mention them here. <br />
                <br /> <span style={{ fontWeight: 'bold' }}>Example:</span> The
                student's parent(s) can help by creating a quiet study space at
                home and encouraging regular breaks to reduce exam-related
                stress. It would also be helpful for them to have open
                communication with the student about their academic challenges.
              </span>
            }
            value={goodThing3}
            handleChange={(e: any) => setGoodThing3(e.target.value)}
            height="5rem"
            descriptionWidth="22rem"
            // minCharactersRequired
          />

          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <button
              //disabled={goodThing?.length < 250}
              style={{ marginTop: 30, color: COLORS.PRIMARY }}
              className={`${styles.feedbackLink} main-green-link`}
              // loading={registeringFeedback}
              onClick={() => {
                if (!registeringFeedback) {
                  registerFeedback({
                    message: regularModalMessage(
                      ratingExplanation.explanation0,
                      ratingValueStep0.toString(),
                      ratingExplanation.explanation1,
                      ratingValueStep1.toString(),
                      ratingExplanation.explanation2,
                      ratingValueStep2.toString(),
                      goodThing1,
                      goodThing2,
                      goodThing3
                    ),
                    studentHomework: {
                      rate: ratingValueStep0,
                      explanation: ratingExplanation.explanation0,
                    },
                    studentEngagement: {
                      rate: ratingValueStep1,
                      explanation: ratingExplanation.explanation1,
                    },
                    studentProgress: {
                      rate: ratingValueStep2,
                      explanation: ratingExplanation.explanation2,
                    },
                    currentStatus: goodThing1,
                    actionPlan: goodThing2,
                    parentHelp: goodThing3,
                  });
                }
              }}
            >
              Submit Feedback
            </button>
            <button
              style={{
                margin: 0,
                marginTop: '20px',
                color: COLORS.TEXT_SECONDARY,
              }}
              className={`${styles.feedbackLink} main-green-link`}
              onClick={decreaseStep}
            >
              Back
            </button>
          </div>
        </>
      )}
    </MentorFeedbackModalTemplate>
  );
};

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
export default MentorFeedbackModalSession;
