import { ReactComponent as EmptyStateIcon } from '@assets/icons/V4/empty-states/happy-calendar.svg';
import { useAuthState, useFeedbackMeetings } from '@shared/react';
import style from '@styles/views/home.module.scss';
import { Spin, Typography } from 'antd';
import moment from 'moment';
import React, { useState } from 'react';
import { COLORS } from '../../../../../v3/global';
import EmptyStateCard from '../../../components/EmptyStateCard';
import MeetingNeedingFeedbackStudent from './MeetingNeedingFeedbackStudent';

const MeetingsNeedingFeedback: React.FC = () => {
  const { data, isLoading, isError } = useFeedbackMeetings();

  const { userId } = useAuthState();

  const impersonateId = localStorage.getItem('impersonate-user-id');

  const [viewAll, setViewAll] = useState(false);

  const meetings = data?.filter(
    (meeting) =>
      meeting.mentor?._id !== (impersonateId ?? userId) &&
      meeting?.feedback?.length === 0
  );

  const filteredMeetings = meetings?.filter(
    (meeting) =>
      (meeting.attended?.student === true ||
        moment().isAfter(meeting.startDate)) &&
      meeting.feedback?.length === 0
  );

  if (isLoading) {
    return <Spin />;
  }
  if (isError) {
    return (
      <Typography.Paragraph style={{ color: COLORS.TEXT_PRIMARY }}>
        An error has occurred...
      </Typography.Paragraph>
    );
  }

  return (
    <div className={style.meetings}>
      {!meetings?.some(
        (meeting) =>
          (meeting.attended?.student === true ||
            moment().isAfter(meeting.startDate)) &&
          meeting.feedback?.length === 0
      ) ? (
        <EmptyStateCard
          icon={<EmptyStateIcon />}
          text={`You have no meetings needing feedback.
Thank you for giving us feedback about our services!`}
        />
      ) : null}

      {!viewAll &&
        filteredMeetings?.map((meeting: any, index) =>
          index < 5 ? (
            <div key={meeting._id}>
              <MeetingNeedingFeedbackStudent meeting={meeting} />
            </div>
          ) : null
        )}

      {viewAll &&
        filteredMeetings?.map((meeting: any, index) => (
          <div key={meeting._id}>
            <MeetingNeedingFeedbackStudent meeting={meeting} />
          </div>
        ))}

      {/*{meetings?.map((meeting: any, index) =>*/}
      {/*  (meeting.attended?.student === true ||*/}
      {/*    moment().isAfter(meeting.startDate)) &&*/}
      {/*  meeting.feedback.length === 0 &&*/}
      {/*  viewAll ? (*/}
      {/*    <div>*/}
      {/*      <MeetingNeedingFeedbackStudent*/}
      {/*        key={meeting._id}*/}
      {/*        meeting={meeting}*/}
      {/*      />*/}
      {/*    </div>*/}
      {/*  ) : index < 5 &&*/}
      {/*    (meeting.attended?.student === true ||*/}
      {/*      moment().isAfter(meeting.startDate)) &&*/}
      {/*    meeting.feedback.length === 0 &&*/}
      {/*    !viewAll ? (*/}
      {/*    <div>*/}
      {/*      <MeetingNeedingFeedbackStudent*/}
      {/*        key={meeting._id}*/}
      {/*        meeting={meeting}*/}
      {/*      />*/}
      {/*    </div>*/}
      {/*  ) : null*/}
      {/*)}*/}

      {filteredMeetings && filteredMeetings?.length > 5 ? (
        <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
          <span
            style={{
              cursor: 'pointer',
              textDecoration: 'underline',
              font: 'normal normal medium 13px/16px Urbane Rounded',
              fontSize: '13px',
              color: COLORS.PRIMARY,
              alignSelf: 'flex-end',
            }}
            onClick={() => setViewAll(!viewAll)}
          >
            {viewAll ? 'View less' : 'View all'}
          </span>
        </div>
      ) : null}
    </div>
  );
};

export default MeetingsNeedingFeedback;
